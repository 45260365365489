import React, {Fragment} from 'react'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import AltList from '../components/altList'
import SolutionText from '../components/solutionText'

const About = ({ data }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          imageAltText={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
        />
      </div>

      <main>
      <section className="bg-white pb-0">
          <MDBContainer>
            <MDBRow>

            <MDBCol>
                <div
                  className="mt-3 richtext divlink pb-5"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBCol>             
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.aboutft &&
          <section className="bg-light-blue">
            <MDBContainer>
              <div>
                {post.frontmatter.aboutft.map((aboutus, index) => {
                  return (
                    <AltList
                      key={index}
                      title={aboutus.title}
                      image={aboutus.image.childImageSharp.gatsbyImageData}
                      alt={aboutus.alt}
                      placement={aboutus.placement}
                      bullets={aboutus.bullets}
                    />
                  )
                })}
              </div>
            </MDBContainer>
          </section>
        }

    {post.frontmatter.section && 
      <>
        {post.frontmatter.section.map((sections, index) => (
          <Fragment key={index}>
            {sections.type === 'text' && (
              <SolutionText data={sections} sectionClass={sections.class} sectionCenter={sections.center} />
            )}
          </Fragment>
        ))}
      </>
    }

     </main>
    </Layout>
  )
}
export default About

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}   
      canonical={location.pathname} 
    />   
  )
}

export const query = graphql`
  query {   
    markdownRemark(frontmatter: { name: { eq: "About" } }) {
      frontmatter {
        meta {
          title
          description 
        }        
        name
        title
        subtitle
        alttext
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        aboutft {
          title
          image {
            childImageSharp {
              gatsbyImageData(width: 960, quality: 90) 
            }
          }
          placement
          alt
          bullets {
            text
          }
        }
        section {
          title
          description
          type
          class
        }
      }
      html
    }
  }
`